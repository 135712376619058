.Modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
  
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}
  
.Modal__container {
    position: relative;
    background-color: #ffffff;
    padding: 1rem;
    width: 400px;
}
  
.Modal__close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background-color: #f8d7d7;
    padding: 0.25rem 0.75rem;
}